"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var MSG_TYPE_CLASS = ['alert-danger', 'alert-info', 'alert-success'];
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            show: true
        };
        return _this;
    }
    Alert.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "alert " + MSG_TYPE_CLASS[this.props.messageType] + " alert-dismissible fade " + (this.state.show ? 'show' : 'd-none'), role: "alert" },
            this.props.message,
            React.createElement("button", { type: "button", className: "close", "data-dismiss": "alert", "aria-label": "Close", onClick: function () { _this.setState({ show: !_this.state.show }); } },
                React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))));
    };
    return Alert;
}(React.Component));
exports.default = Alert;
var AlertMessageTypes;
(function (AlertMessageTypes) {
    AlertMessageTypes[AlertMessageTypes["Error"] = 0] = "Error";
    AlertMessageTypes[AlertMessageTypes["Info"] = 1] = "Info";
    AlertMessageTypes[AlertMessageTypes["Success"] = 2] = "Success";
})(AlertMessageTypes = exports.AlertMessageTypes || (exports.AlertMessageTypes = {}));
