"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./sidenavaccordion.scss");
exports.SideNavAccordion = function (props) {
    var children = react_1.default.Children.toArray(props.children);
    var _a = react_1.useState(props.defaultOpen), open = _a[0], updateOpen = _a[1];
    var toggleOpen = function () {
        updateOpen(!open);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("li", { onClick: toggleOpen },
            props.title,
            react_1.default.createElement("i", { className: "fas fa-chevron-" + (open ? 'down' : 'right'), style: { float: 'right', marginRight: '1em' } })),
        react_1.default.createElement("li", { className: "sidenav-accordion " + (open ? 'open' : 'closed') },
            react_1.default.createElement("ul", null, children.map(function (child) {
                return react_1.default.cloneElement(child);
            })))));
};
exports.SideNavAccordion.defaultProps = {
    defaultOpen: false,
};
exports.default = exports.SideNavAccordion;
