"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./sidenavsearch.scss");
exports.SideNavSearch = function (props) {
    var children = react_1.default.Children.toArray(props.children);
    var _a = react_1.useState(children), filteredItems = _a[0], setFilteredItems = _a[1];
    var onChange = function (e) {
        setFilteredItems(children.filter(function (child) {
            if (e.target.value === '')
                return true;
            return child.props.title.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
        }));
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("li", null,
            react_1.default.createElement("input", { type: "text", onChange: onChange })),
        filteredItems.map(function (child) {
            return child;
        })));
};
exports.default = exports.SideNavSearch;
