"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var InstanceContext_1 = require("../../context/InstanceContext");
var sidenav_1 = require("./sidenav");
exports.Sidebar = function (props) {
    var instanceChanged = function (item) {
        props.updateInstance(item.data.instance.id);
    };
    var _a = react_1.useState(null), activeItem = _a[0], setActiveItem = _a[1];
    var onL1Clicked = function (item) { return setActiveItem(item.title.toLowerCase()); };
    return (react_1.default.createElement(sidenav_1.SideNav, { onClick: function (item) { return props.navigation.push(item.url); } },
        react_1.default.createElement(sidenav_1.SideNavItem, { title: "Horseman", icon: { iconname: "fas fa-horse-head" }, url: "/" }),
        react_1.default.createElement(sidenav_1.SideNavItem, { title: "Dashboard", icon: { iconname: "fas fa-tachometer-alt" }, url: "/", collapse: true, active: activeItem === 'dashboard', onClick: onL1Clicked }),
        react_1.default.createElement(sidenav_1.SideNavItem, { title: "Content", icon: { iconname: "fas fa-book" }, url: "/content/types", collapse: true, active: activeItem === 'content', onClick: onL1Clicked }),
        react_1.default.createElement(sidenav_1.SideNavItem, { title: "Users", icon: { iconname: "fas fa-users" }, url: "/users", collapse: true, active: activeItem === 'users', onClick: onL1Clicked }),
        react_1.default.createElement(sidenav_1.SideNavItem, { title: "Instance", icon: { iconname: "fas fa-layer-group" }, active: activeItem === 'instance', onClick: onL1Clicked },
            react_1.default.createElement(sidenav_1.SideNavItem, { title: "Add Instance", url: "/instance" }),
            react_1.default.createElement(sidenav_1.SideNavItem, { title: "Swith Instance" },
                react_1.default.createElement(InstanceContext_1.InstanceContext.Consumer, null, function (context) {
                    return (react_1.default.createElement(react_1.default.Fragment, null, context.instances.map(function (instance, i) {
                        return react_1.default.createElement(sidenav_1.SideNavItem, { key: i, title: instance.name, data: { instance: instance }, onClick: instanceChanged, collapse: true });
                    })));
                })))));
};
exports.default = exports.Sidebar;
