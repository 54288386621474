"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var React = __importStar(require("react"));
var RecordInputShortText = /** @class */ (function (_super) {
    __extends(RecordInputShortText, _super);
    function RecordInputShortText() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.changeHandler = function (e) {
            _this.props.changeHandler(e.target.value, _this.props.field);
        };
        return _this;
    }
    RecordInputShortText.prototype.render = function () {
        return (React.createElement("div", { className: "form-group" },
            React.createElement("label", null, this.props.field.name),
            React.createElement("input", { type: "text", className: "form-control", defaultValue: this.props.value, onChange: this.changeHandler })));
    };
    return RecordInputShortText;
}(react_1.Component));
exports.default = RecordInputShortText;
