"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var navListeners = {
    width: [],
    activeItem: [],
};
exports.addSideNavListener = function (event, callback) {
    navListeners[event].push(callback);
};
exports.removeSideNavListener = function (event, callback) {
    navListeners[event] = navListeners[event].filter(function (cb) { return cb !== callback; });
};
exports.notifySideNavListeners = function (event) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    navListeners[event].forEach(function (listener) { return listener.apply(void 0, args); });
};
exports.default = {
    addSideNavListener: exports.addSideNavListener,
    removeSideNavListener: exports.removeSideNavListener,
    notifySideNavListeners: exports.notifySideNavListeners,
};
