"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
exports.Modal = function (props) {
    return (React.createElement("div", { className: "modal", tabIndex: -1, role: "dialog", id: props.id, style: { display: props.show ? 'block' : 'none', background: 'rgba(0,0,0,.5)' } },
        React.createElement("div", { className: "modal-dialog", role: "document" },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "modal-header" },
                    React.createElement("h5", { className: "modal-title" }, props.title),
                    React.createElement("button", { type: "button", className: "close", "data-dismiss": "modal", "aria-label": "Close" },
                        React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("p", null, props.text)),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement("button", { type: "button", className: "btn btn-primary", "data-dismiss": "modal", onClick: function () { return props.onDismiss(true); } }, props.buttonText),
                    React.createElement("button", { type: "button", className: "btn btn-secondary", "data-dismiss": "modal", onClick: function () { return props.onDismiss(false); } }, "Cancel"))))));
};
